import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactPlayer from "react-player";
import "../style/normalize.css";
import "../style/all.scss";
import styles from "../style/pages/video.module.scss";

const Page = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const pageData = data.allMarkdownRemark.edges;

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Videos"
        keywords={[`video`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template">
        <div className={styles.grid}>
          {pageData.map((item) => {
            let { title, date, url } = item.node.frontmatter;
            return (
              <div className={styles.container}>
                <div className={styles.content}>
                  <span>{date}</span>
                  <a href={url} target="_blank">
                    <h6>{title}</h6>
                  </a>
                  {item.node.html && (
                    <div
                      className={styles.description}
                      dangerouslySetInnerHTML={{
                        __html: item.node.excerpt,
                      }}
                    />
                  )}
                </div>
                <ReactPlayer url={url} controls width="100%" height="100%" />
                <div style={{ height: "2rem" }} />
              </div>
            );
          })}
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/video/" } }
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 160)
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            url
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => <Page location={props.location} data={data} {...props} />}
  />
);
